<template>
  <div>
    <v-btn
      @click="importFileFlotingButton"
      :style="colorFlotingButton"
      fab
      dark
      fixed
      bottom
      right
      :disabled="opensnackbar"
    >
      <input
        ref="importdoc"
        class="d-none"
        type="file"
        @change="uploadFileFlotingButton"
        multiple
      />
      <v-icon size="40">mdi-plus</v-icon>
    </v-btn>
      <v-snackbar
        v-model="opensnackbar"
        top
        :timeout="timeout"
        vertical
        style="z-index: 1;"
      >
        <v-card-subtitle>
          <span v-if="loopsuccess === false">{{
            datanameuploading | subStr
          }}</span>
          <span v-if="loopsuccess === false">
            ({{ percentfileuploading }} %)
            {{ countuploadfiles + "/" + total }}</span
          >
          <span v-else-if="loopsuccess === true && countcancel == 1">{{
            $t("dragdrop.cancelupload")
          }}</span>
          <span v-else-if="loopsuccess === true && countuploadfilesfail <= 0">{{
            $t("dragdrop.uploadsuccess")
          }}</span>
          <span v-else-if="loopsuccess === true && countuploadfilesfail > 0">{{
            $t("dragdrop.cannotupload")
          }}</span>
          <v-btn
            icon
            style="color: red;"
            v-if="countuploadfilesfail > 0"
            @click="(opendetailupload = true), (opensnackbar = false)"
          >
            <v-icon color="red">mdi-information</v-icon>
          </v-btn>
        </v-card-subtitle>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="red"
            v-bind="attrs"
            class="mt-n2"
            text
            @click="cancelUpload()"
            :disabled="opencancel"
          >
            {{ $t("dragdrop.cancel") }}
          </v-btn>
          <v-btn
            text
            color="red"
            v-bind="attrs"
            class="mt-n2"
            v-if="
              (countuploadfilesfail > 0 && loopsuccess === true) ||
                openbtnclose === true
            "
            @click="(opensnackbar = false), $emit('loadfile')"
          >
            {{ $t("dragdrop.close") }}
          </v-btn>
        </template>
      </v-snackbar>
    <detailupload
      :show="opendetailupload"
      :dataupload="arrayfaildataupload"
      @closedialog="(opendetailupload = false), (opensnackbar = true)"
    ></detailupload>
    <dialogalertduplicatefile
      :show="openalertduplicatefile"
      :listfileduplicate="listfileduplicate"
      @skip="(fileduplicate = ''), setStatusFileDuplicate()"
      @replace="
        (fileduplicate = 'create_tag_version'), setStatusFileDuplicate()
      "
      @copy="(fileduplicate = 'create_copy'), setStatusFileDuplicate()"
    ></dialogalertduplicatefile>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { v4 as uuidv4 } from "uuid";
import axios, { CancelToken } from "axios";
const detailupload = () => import("@/components/optional/dialog-detailupload");
const dialogalertduplicatefile = () =>
  import("@/components/optional/dialog-alert-duplicate-file.vue");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data() {
    return {
      moredetail: false,
      loaddataprogress: false,
      selectingImportFlotintButton: false,
      fileImportFlotintButton: null,
      opensnackbar: false,
      source: "",
      numchunk: 0,
      allchunk: [],
      arraydataupload: [],
      countuploadfiles: 0,
      datanameuploading: "",
      percentfileuploading: 0,
      openbtnclose: false,
      panel_expansion: [1],
      loopsuccess: false,
      tempdataupload: [],
      countcancel: 0,
      countuploadfilesfail: 0,
      total: 0,
      opencancel: true,
      loading: false,
      storage_full: false,
      check_detailfail: false,
      timeout: -1,
      opendetailupload: false,
      arrayfaildataupload: [],
      fileduplicate: "",
      // fileduplicate: "create_copy",
      listfileduplicate: [],
      openalertduplicatefile: false,
    };
  },
  filters: {
    subStr: function(val) {
      if (val.length > 25) return val.substring(0, 25) + "...";
      else return val;
    },
  },
  components: {
    detailupload,
    dialogalertduplicatefile,
  },
  props: ["departmentid"],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
      "storage_usage",
      "storage_usage_biz",
      "storage_max_personal_biz",
      "storage",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    percentuploadedallfile() {
      return (
        this.arraydataupload.reduce((a, b) => {
          let x =
            b["status"] === "upload success" || b["status"] === "upload denied"
              ? 101
              : b["value"];
          return a + x;
        }, -1) / this.arraydataupload.length ||
        this.tempdataupload.reduce((a, b) => {
          let x =
            b["status"] === "upload success" || b["status"] === "upload denied"
              ? 101
              : b["value"];
          return a + x;
        }, -1) / this.total
      );
    },
    // สีพื้นหลังของ Floating Button (ปุ่มลอยขวาล่าง)
    colorFlotingButton() {
      if (this.$vuetify.theme.dark) {
        return "background: #9A9A9A;";
      } else {
        return "background: linear-gradient(270deg, #176C91 0%, #71B5D8 100%);";
      }
    },
  },
  methods: {
    convertstorageToByte(_storage) {
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1024
            : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1048576
            : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1073741824
            : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1099511627776
            : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0]
            : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },
    // เด้งให้เลือกไฟล์
    importFileFlotingButton() {
      this.selectingImportFlotintButton = true;
      this.fileImportFlotintButton = null;
      this.$refs.importdoc.value = null;
      this.fileduplicate = "";
      this.listfileduplicate = [];
      this.arraydataupload = [];
      window.addEventListener(
        "focus",
        () => {
          this.selectingImportFlotintButton = false;
        },
        { once: true }
      );
      this.$refs.importdoc.click();
    },
    setStatusFileDuplicate() {
      console.log("this.fileduplicate", this.fileduplicate);
      // กดข้าม
      if (this.fileduplicate === "") {
        this.loopsuccess = true;
        setTimeout(() => {
          this.opensnackbar = false;
          this.$emit("loadfile");
        }, 2500);
      } 
      // แทนที่หรือคัดลอก
      else {
        this.arraydataupload = this.listfileduplicate;
        this.listfileduplicate = [];
        this.uploadFileFlotingButton();
      }
      this.openalertduplicatefile = false;
    },
    // ฟังก์ชันวนอัปโหลดไฟล์สำหรับ Floting Button
    async uploadFileFlotingButton(e) {
      this.clearDataSnackbarUpload();
      this.opensnackbar = true;
      let response;
      // เช็คว่ามีการอัปโหลดซ้ำจากการเลือก file_duplicate
      if (this.arraydataupload.length === 0) {
        this.fileImportFlotintButton = e.target.files;
        this.total = e.target.files.length;
        await this.createDataUpload();
      } else {
        this.total = this.arraydataupload.length;
      }
      for (let i = 0; i < this.arraydataupload.length; i++) {
        // เช็คความจุ storage
        let remain;
        let maxsize = this.convertstorageToByte(this.dataStorageMax);
        if (this.dataAccountActive.type === "Business") {
          remain = this.convertstorageToByte(this.storage_usage_biz);
        } else {
          remain = this.convertstorageToByte(this.storage_usage);
        }
        let calRemain = maxsize - (remain + this.arraydataupload[i].size);
        if (calRemain < 0) {
          this.arraydataupload[i].status = "upload denied";
          this.arraydataupload[i].msg = this.$t("dragdrop.yourspacenotenough");
          this.countuploadfilesfail++;
          this.arrayfaildataupload.push(this.arraydataupload[i]);
        } else {
          this.datanameuploading = this.arraydataupload[i].name;
          this.countuploadfiles++;
          this.opencancel = false;
          // เช็คว่าเป็น chunk หรือ no chunk
          if (this.fileImportFlotintButton[i].size > 104857600) {
            response = await this.loopUploadChunk(this.arraydataupload[i]);
          } else {
            response = await this.loopUploadNoChunk(this.arraydataupload[i]);
          }
          this.opencancel = true;
          // กดยกเลิก
          if (response.status === "upload cancel") {
            this.arraydataupload[i].status = "upload cancel";
            break;
          }
          if (
            response.status === "upload success" &&
            response.msg === "duplicate file"
          ) {
            // สร้าง data เพิ่อเก็บข้อมูล file_duplicate
            let datafileduplicate = {
              file: this.arraydataupload[i]["file"],
              name: this.arraydataupload[i]["name"],
              size: this.arraydataupload[i]["size"],
              value: 0,
              canceltoken: this.arraydataupload[i]["canceltoken"],
              id: this.arraydataupload[i]["id"],
              chunkkey: this.arraydataupload[i]["chunkkey"],
              status: this.arraydataupload[i]["status"],
              msg: this.arraydataupload[i]["msg"],
              icon: this.arraydataupload[i]["icon"],
            };
            this.listfileduplicate.push(datafileduplicate);
          }
          // อัปสำเร็จ
          else if (response.status === "upload success") {
            this.arraydataupload[i].status = "upload success";
            this.arraydataupload[i].value = 100;
            continue;
          }
          // storage เต็ม
          else if (
            response.status === "upload denied" &&
            response.msg === "Your space not enough"
          ) {
            this.arraydataupload[i].status = "upload denied";
            this.arraydataupload[i].msg = this.$t(
              "dragdrop.yourspacenotenough"
            );
            this.countuploadfilesfail++;
            this.arrayfaildataupload.push(this.arraydataupload[i]);
          }
          // storage เต็ม
          else if (
            response.status === "upload denied" &&
            response.msg === "storage is full"
          ) {
            this.arraydataupload[i].status = "upload denied";
            this.arraydataupload[i].msg = this.$t(
              "dragdrop.yourspacenotenough"
            );
            this.countuploadfilesfail++;
            this.arrayfaildataupload.push(this.arraydataupload[i]);
          }
          // ไฟล์เกิน 5 GB
          else if (
            response.status === "upload denied" &&
            response.msg === "File Size More Than 5 GB"
          ) {
            this.arraydataupload[i].status = "upload denied";
            this.arraydataupload[i].msg = this.$t("dragdrop.morefivegb");
            this.countuploadfilesfail++;
            this.arrayfaildataupload.push(this.arraydataupload[i]);
          }
          // ไฟล์เกิน 5 GB
          else if (
            response.status === "upload denied" &&
            response.msg.includes("Size file more than")
          ) {
            this.arraydataupload[i].status = "upload denied";
            this.arraydataupload[i].msg = this.$t("dragdrop.morefivegb");
            this.countuploadfilesfail++;
            this.arrayfaildataupload.push(this.arraydataupload[i]);
          } else {
            this.arraydataupload[i].status = "upload denied";
            this.arraydataupload[i].msg = this.$t(
              "dragdrop.cannotdetailupload"
            );
            this.countuploadfilesfail++;
            this.arrayfaildataupload.push(this.arraydataupload[i]);
          }
        }
      }
      if (this.listfileduplicate.length > 0) {
        this.openalertduplicatefile = true;
      }
      this.loopsuccess = true;
      if (
        this.loopsuccess === true &&
        this.countuploadfilesfail <= 0 &&
        this.countcancel !== 1 &&
        this.listfileduplicate.length === 0
      ) {
        // set timeout เมื่ออัปโหลดสำเร็จทั้งหมด
        setTimeout(() => {
          this.opensnackbar = false;
          this.$emit("loadfile");
        }, 2500);
      }
    },
    // สร้าง data สำหรับเก็บข้อมูลไฟล์แต่ละไฟล์
    createDataUpload() {
      for (let i = 0; i < this.fileImportFlotintButton.length; i++) {
        // ปั้นดาต้าใหม่
        let datafileupload = {};
        datafileupload["file"] = this.fileImportFlotintButton[i];
        datafileupload["name"] = this.fileImportFlotintButton[i].name;
        datafileupload["size"] = this.fileImportFlotintButton[i].size;
        datafileupload["value"] = 0;
        datafileupload["canceltoken"] = null;
        datafileupload["id"] = i;
        datafileupload["chunkkey"] = "";
        datafileupload["status"] = "start";
        datafileupload["msg"] = "";
        datafileupload["icon"] = this.seticon(
          this.fileImportFlotintButton[i].name.split(/\.(?=[^\.]+$)/)[1]
        );
        this.arraydataupload.push(datafileupload);
      }
    },
    // วนลูป อัปโหลดไฟล์ chunk file
    async loopUploadChunk(item) {
      this.source = CancelToken.source();
      // เก็บ canceltoken ของแต่ละ request
      this.arraydataupload[item.id].canceltoken = this.source.token;
      try {
        // เรียกฟังก์ชันสำหรับการสร้าง blob ในการอัปโหลดแบบ chunk
        await this.createChunk(item);
        // เรียกฟังก์ชันสำหรับการสร้าง key ของ chunk
        await this.uuidGenerate(item);
        for (let i = 0; i < this.allchunk.length; i++) {
          this.numchunk = i;
          let payloadfilechunk = await this.checkPayloadFileChunk(item);
          let filechunk = await this.uploadFileChunk(payloadfilechunk, item);
          if (filechunk.status !== "upload success") {
            // push error message
            this.arraydataupload[item.id]["msg"] = filechunk.msg;
          }
          if (i === this.allchunk.length - 1) {
            let checkpayloadfilemerge = await this.checkpayloadfilemerge(item);
            let uploadfilemerge = await this.uploadfilemerge(
              checkpayloadfilemerge,
              item
            );
            if (uploadfilemerge.status === "upload success") {
              return new Promise((resolve, reject) => {
                resolve({ status: "upload success", msg: "" });
              });
            } else if (uploadfilemerge.status === "upload cancel") {
              return new Promise((resolve, reject) => {
                resolve({ status: "upload cancel", msg: "" });
              });
            } else {
              // push error message
              this.arraydataupload[item.id]["msg"] = uploadfilemerge.msg;
              return new Promise((resolve, reject) => {
                resolve({ status: "upload denied", msg: uploadfilemerge.msg });
              });
            }
          }
        }
      } catch (err) {
        if (this.countcancel == 1) {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload cancel", msg: err });
          });
        } else {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload denied", msg: err });
          });
        }
      }
    },
    // สร้างคีย์สำหรับ chunk
    uuidGenerate(item) {
      let myuuid = uuidv4();
      this.arraydataupload[item.id].chunkkey = myuuid;
    },
    // แบ่งไฟล์เป็น chunk
    async createChunk(item) {
      this.allchunk = [];
      var size = 5242880;
      var chunklength = 0;
      try {
        chunklength = Math.ceil(
          this.fileImportFlotintButton[item.id].size / size
        );
        for (let i = 0; i < chunklength; i++) {
          this.allchunk.push(
            this.fileImportFlotintButton[item.id].slice(
              i * size,
              Math.min(
                i * size + size,
                this.fileImportFlotintButton[item.id].size
              ),
              this.fileImportFlotintButton.type
            )
          );
        }
      } catch (ex) {
        console.log(ex);
      }
    },

    // ฟังก์ชันสร้าง payload ของ chunk แต่ละอัน
    async checkPayloadFileChunk(item) {
      let parent = this.sendParentfolder();
      try {
        const formData = new FormData();
        formData.append(`account_id`, this.dataAccountId);
        formData.append(`folder_id`, parent);
        formData.append(`size_file`, item.size);
        formData.append(`file_part`, item.name + `.part_${this.numchunk}`);
        formData.append(`key`, this.arraydataupload[item.id]["chunkkey"]);
        formData.append(`file_duplicate`, this.fileduplicate);
        if (this.allchunk[this.numchunk]) {
          formData.append(`blob`, this.allchunk[this.numchunk]);
        }
        return formData;
      } catch (ex) {
        console.log(ex);
      }
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = this.account_active["directory_id"];
      } else {
        this.parentfolder = this.$route.params.id;
      }
      return this.parentfolder;
    },
    // ฟังก์ชันอัปโหลดไฟล์ที่เป็น chunk
    async uploadFileChunk(payload, item, i) {
      let len_file = this.allchunk.length;
      try {
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/upload_files_chunk",
          cancelToken: this.arraydataupload[item.id]["canceltoken"],
          data: payload,
          headers: {},

          onUploadProgress: (e) => {
            this.arraydataupload[item.id]["status"] = "uploading";
          },
        });
        if (result.data.status === "OK") {
          this.arraydataupload[item.id].value = Math.round(
            (this.numchunk / len_file) * 100
          );
          this.percentfileuploading = this.arraydataupload[item.id]["value"];
          return new Promise((resolve, reject) => {
            resolve({ status: "upload success", msg: "" });
          });
        } else {
          this.arraydataupload[item.id]["value"] = 500;
          return new Promise((resolve, reject) => {
            resolve({ status: "upload denied", msg: result.data.errorMessage });
          });
        }
      } catch (err) {
        if (this.countcancel == 1) {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload cancel", msg: err });
          });
        } else {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload denied", msg: err });
          });
        }
      }
    },
    // ฟังก์ชันอัปโหลดไฟล์ no chunk
    async loopUploadNoChunk(item) {
      this.source = CancelToken.source();
      // เก็บ canceltoken ของแต่ละ request
      this.arraydataupload[item.id].canceltoken = this.source.token;
      let payloadnochunk = await this.checkPayLoadNoChunk(item);
      let auth = await gbfGenerate.generateToken();
      try {
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files_v2",
          cancelToken: this.arraydataupload[item.id].canceltoken,
          data: payloadnochunk,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.arraydataupload[item.id]["value"] = 95;
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
            } else {
              this.arraydataupload[item.id]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
            }
          },
        });
        if (result.data.status === "OK") {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload success", msg: result.data.message });
          });
        } else {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload denied", msg: result.data.errorMessage });
          });
        }
      } catch (err) {
        if (this.countcancel == 1) {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload cancel", msg: err });
          });
        } else {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload denied", msg: err });
          });
        }
      }
    },
    // ฟังก์ชันสร้าง payload สำหรับ merge chunk
    async checkpayloadfilemerge(item) {
      let parent;
      const formData = new FormData();
      try {
        parent = this.sendParentfolder();
        formData.append(`account_id`, this.dataAccountId);
        formData.append(`user_id`, this.dataUsername);
        formData.append(`folder_id`, parent);
        formData.append(`file_duplicate`, this.fileduplicate);
        formData.append(`file_name`, item.name);
        formData.append(`remark`, "");
        formData.append(`len_file`, Math.ceil(this.allchunk.length));
        formData.append(`key`, this.arraydataupload[item.id]["chunkkey"]);
        return formData;
      } catch (ex) {
        console.log(ex);
      }
    },
    // ฟังก์ชันสร้าง payload สำหรับไฟล์ no chunk
    async checkPayLoadNoChunk(item) {
      let parent;
      const formData = new FormData();
      try {
        parent = this.sendParentfolder();
        formData.append("file", item.file);
        formData.append("file_name", item.name);
        formData.append("size_file", item.size);
        formData.append("folder_id", parent);
        formData.append("user_id", this.dataUsername);
        formData.append("department_id", this.departmentid);
        formData.append("account_id", this.dataAccountId);
        formData.append("file_duplicate", this.fileduplicate);
        formData.append("remark", "");
        return formData;
      } catch (ex) {
        console.log(ex);
      }
    },
    // ฟังก์ชัน merge chunk
    async uploadfilemerge(payloadmerge, item) {
      let auth = await gbfGenerate.generateToken();
      try {
        let result = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/merge_files_chunk",
          cancelToken: this.arraydataupload[item.id]["canceltoken"],
          data: payloadmerge,
          headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.arraydataupload[item.id]["value"] = 99;
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
            } else {
              this.arraydataupload[item.id]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
              this.percentfileuploading = this.arraydataupload[item.id][
                "value"
              ];
            }
          },
        });
        this.numchunk = 0;
        if (result.data.status === "OK") {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload success", msg: result.data.message });
          });
        } else {
          return new Promise((resolve, reject) => {
            resolve({
              status: "upload denied",
              msg: result.data.errorMessage,
            });
          });
        }
      } catch (err) {
        if (this.countcancel == 1) {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload cancel", msg: err });
          });
        } else {
          return new Promise((resolve, reject) => {
            resolve({ status: "upload denied", msg: err });
          });
        }
      }
    },
    // ฟังก์ชัน clear ข้อมูล
    clearDataSnackbarUpload() {
      this.countcancel = 0;
      this.total = 0;
      this.datanameuploading = "";
      this.countuploadfiles = 0;
      this.countuploadfilesfail = 0;
      this.arrayfaildataupload = [];
      this.loopsuccess = false;
      this.countuploadfilesfail = 0;
      this.percentfileuploading = 0;
      this.openbtnclose = false;
    },
    // ฟังก์ชันยกเลิกการอัปโหลด
    cancelUpload() {
      this.countcancel = 1;
      this.openbtnclose = true;
      this.tempdataupload = [...this.arraydataupload];
      this.opencancel = true;
      this.source.cancel();
    },
    // ตั้ง icon
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box", "blue-grey"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "csv") {
        dataicon = ["mdi-file-table", "green"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png" ||
        parameter === "gif"
      ) {
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        dataicon = ["mdi-file", "black"];
      }
      return dataicon;
    },
  },
};
</script>
<style></style>
